import { type DocNode } from '@atlaskit/adf-schema';
import { type IssueCreateEmbedSuccessPayload } from '@atlassian/gic-anywhere';

export type StateSource =
	| 'aiIssueCreateGetConfluencePageState'
	| 'aiIssueCreateFetchSuggestedFieldsState';

export type ContentType = 'page' | 'blogpost' | 'whiteboard' | 'database' | 'embed';

export const AssistanceServiceMessageTemplate = {
	// https://bitbucket.org/atlassian/assistance-service/src/02087c3b6569f042ec338e08e8e0e152c4563dad/src/schema/message_publisher.py#lines-74
	ACCEPTABLE_USE_VIOLATIONS: 'ACCEPTABLE_USE_VIOLATIONS',
	ACCEPTABLE_USE_EVALUATION_EXCEPTION: 'ACCEPTABLE_USE_EVALUATION_EXCEPTION',
	AI_DISABLED: 'AI_DISABLED',
	ANSWER_UNAVAILABLE_FOR_CONTENT: 'ASWER_UNAVAILABLE_FOR_CONTENT', // typo is present in the Python version
	CONVERSATION_CHANNEL_UNAUTHORIZED: 'CONVERSATION_CHANNEL_UNAUTHORIZED',
	EXCEEDING_CONTEXT_LENGTH_ERROR: 'EXCEEDING_CONTEXT_LENGTH_ERROR',
	EXPERIENCE_NOT_ENABLED_FOR_CUSTOMER_ACCOUNT: 'EXPERIENCE_NOT_ENABLED_FOR_CUSTOMER_ACCOUNT',
	FEATURE_DISABLED_ON_SITE: 'FEATURE_DISABLED_ON_SITE',
	FEATURE_DISABLED: 'FEATURE_DISABLED',
	HIPAA_CONTENT_DETECTED: 'HIPAA_CONTENT_DETECTED',
	NETWORK_ERROR: 'NETWORK_ERROR',
	NO_AGENT: 'NO_AGENT',
	NO_ANSWER: 'NO_ANSWER',
	NOT_ENOUGH_CONTENT_ERROR: 'NOT_ENOUGH_CONTENT_ERROR',
	OPENAI_RATE_LIMIT_USER_ABUSE: 'OPENAI_RATE_LIMIT_USER_ABUSE',
	PLUGIN_ERRORED: 'PLUGIN_ERRORED',
	RATE_LIMIT: 'RATE_LIMIT',
	UNEXPECTED: 'UNEXPECTED',
	INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
	// Custom
	NO_ANSWER_SUGGESTED_ISSUES: 'NO_ANSWER_SUGGESTED_ISSUES',
	FE_REQUEST_EXCEEDING_TOKEN_LENGTH: 'FE_REQUEST_EXCEEDING_TOKEN_LENGTH',
	UNKNOWN_ERROR: 'UNKNOWN_ERROR', // generic error
} as const;
// Type for the object
export type AssistanceServiceMessageTemplateType = typeof AssistanceServiceMessageTemplate;
// Type for the values
export type AssistanceServiceMessageTemplateValues =
	AssistanceServiceMessageTemplateType[keyof AssistanceServiceMessageTemplateType];

export type ConfluenceAiIssueCreateProps = {
	/**
	 * cloudId of the Jira instance
	 */
	cloudId: string;
	/**
	 * The consumer of AI Issue Create
	 * e.g. 'confluenceHighlight', 'rovoChat'
	 */
	consumer: string;
	/**
	 * The content type of the page - page or blog
	 */
	contentType: ContentType;
	/**
	 * Confluence page ID (aka contentId)
	 */
	pageId: string;
	/**
	 * The snippet of text that is highlighted in ADF format
	 */
	highlightedTextAdf: DocNode;
	/*
	 * overrideCloudId for site picker on GIC
	 */
	overrideCloudIdForSitePicker?: string;
	/**
	 * Test id for the component (optional)
	 */
	testId?: string;
	/**
	 * Time in ms for when iFrame should timeout (optional)
	 */
	timeoutMs?: number;
	/**
	 * Callback fired when Global issue create (GIC) is initialized
	 */
	onInit: () => void;
	/**
	 * Callback fired when issue create is successful
	 */
	onSuccess: (payload: IssueCreateEmbedSuccessPayload) => void;
	/**
	 * Callback fired when issue create fails
	 */
	onFailure: (
		error: Error | unknown,
		message_template?: AssistanceServiceMessageTemplateValues,
	) => void;
	/**
	 * Callback fired when issue create is closed
	 */
	onClose: (isCreateSuccess?: boolean) => void;
	/**
	 * Callback fired when state is clanged
	 */
	onStateChange: (state: AiIssueCreateState, source: StateSource) => void;
	/**
	 * Callback fired when issue create times out
	 */
	onTimeout?: () => void;
	/**
	 * Function to set the analytics context for issue create AI.
	 */
	setAiAnalyticsContext?: (key: string, value: number) => void;
};

export type FetchIssueCreateDataProps = {
	cloudId: string;
	/**
	 * Confluence page in markdown plus format
	 */
	confluencePage: string;
	/**
	 * The X-Experience-Id to be used in the request to Assistance Service
	 */
	experienceId: string;
	/**
	 * The snippet of text that is highlighted in markdown plus format
	 */
	highlightedText: string;
	/**
	 * The product to be used in the request to Assistance Service. By default it will be used for the X-Product and request body application type.
	 */
	product?: string;
	/**
	 * The signal to abort the fetch
	 */
	signal: AbortSignal;
	/**
	 * The override X-Product to be used in the request to Assistance Service.
	 * This is specifically used in AIWC between Jira and Confluence when Confluence has AI toggled off but Jira has it on.
	 * In this case we can use Jira's enablement to promote more AI usage across products.
	 */
	overrideXProduct?: string;
};

export type SuggestionResult = {
	suggestion: SuggestedIssue;
	usage: {
		completion_tokens: number;
		duration: number;
		max_tokens: number | null;
		prompt_tokens: number;
		request_count: number;
		temperature: number | null;
		total_tokens: number;
	};
};

export type SuggestedIssue = {
	summary: string;
	/**
	 * Description page in markdown plus format
	 */
	description: string;
};

export type AiIssueCreateState = 'abort' | 'loading' | 'error' | 'idle';

export type GetConfluencePage = {
	cloudId: string;
	contentType: ContentType;
	pageId: string;
	signal: AbortSignal;
	/**
	 * The X-Product to be used in the request to Assistance Service
	 */
	product?: string;
	/**
	 * The X-Experience-Id to be used in the request to Assistance Service
	 */
	experienceId: string;
};

// Error shape is polymorphic as they can come from either:
// 1. assistance-service
// 2. convo-ai
// Convo AI errors come in a consistent shape
// Assistance Service have multiple error shapes
export type ConvoAiErrorPayload = {
	error_code?: string;
	error_message?: string;
	message_template?: AssistanceServiceMessageTemplateValues;
	errors?: string[];
	errorMessage?: string;
	content?: string;
};

export type AssistanceServiceError = {
	content: string;
	status_code: number;
	message_template: AssistanceServiceMessageTemplateValues;
};
